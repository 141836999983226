import { diffieHellman } from 'crypto';
import moment from 'moment';
import { Moment } from 'moment';
import pluralize from 'pluralize';

const dateToDiff = (date: Moment) => {
	const diff = '';
	const m1 = moment();
	const m2 = date;
	const y = m1.diff(m2, 'years');
	if (y > 0) {
		return pluralize('years', y);
	}
	const m = m1.diff(m2, 'months');
	if (m > 0) {
		return pluralize('month', m);
	}
	const w = m1.diff(m2, 'weeks');
	if (w > 0) {
		return pluralize('week', w);
	}
	const d = m1.diff(m2, 'days');
	if (d > 0) {
		return pluralize('day', d);
	}
	const h = m1.diff(m2, 'hours');
	if (h > 0) {
		return pluralize('hour', h, true);
	}
	const min = m1.diff(m2, 'minutes');
	if (min > 0) {
		return pluralize('minute', min, true);
	}
	const s = m1.diff(m2, 'seconds');
	if (s > 0) {
		return pluralize('second', s, true);
	}
	return diff;
};

export default {
	dateToDiff,
} as const;
