import './App.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Game, Result, Status } from './Result';
import moment from 'moment';
import Util from './Util';

type Props = {
	game: Game;
	onClicked: (game: Game) => void;
};

const GameListItem: React.FC<Props> = (props) => {
	const { game, onClicked } = props;

	const date = moment.unix(game.date.seconds);
	const update = moment.unix(game.update.seconds);
	const ymd = date ? date.format('YYYY-MM-DD') : `-----`;
	const location = game.location ? game.location : `-----`;

	const first = game.score.first[9];
	const second = game.score.second[9];
	return (
		<>
			<li key={game.id} className={`list-item`} onClick={() => onClicked(game)}>
				<ul className={`location-date`}>
					<li className={`location`}>{location}</li>
					<li className={`date`}>{ymd}</li>
				</ul>
				<ul className={`team-score`}>
					<li className={`team`}>{game.teamA}</li>
					<li className={`score`}>
						<ul>
							<li className={first && second && first > second ? `accent` : ``}>{first}</li>
							<li className={`vs`}>{`-`}</li>
							<li className={first && second && second > first ? `accent` : ``}>{second}</li>
						</ul>
					</li>
					<li className={`team`}>{game.teamB}</li>
				</ul>
				<ul className={`update`}>
					<li>
						{Util.dateToDiff(update)}
						{` ago`}
					</li>
				</ul>
			</li>
		</>
	);
};

export default GameListItem;
