import React from 'react';

import { Game } from './Result';

type Props = { game: Game };

const TableCount: React.FC<Props> = (props: Props) => {
	const { game } = props;
	const {
		count: { ball, strike, out },
	} = game;

	return (
		<>
			<table className={`count-table`}>
				<tbody>
					<tr>
						<th className={`count-label user-select`}>B</th>
						<td className={ball > 0 ? `count-ball` : ``}></td>
						<td className={ball > 1 ? `count-ball` : ``}></td>
						<td className={ball > 2 ? `count-ball` : ``}></td>
					</tr>
					<tr>
						<th className={`count-label user-select`}>S</th>
						<td className={strike > 0 ? `count-strike` : ``}></td>
						<td className={strike > 1 ? `count-strike` : ``}></td>
						<td></td>
					</tr>
					<tr>
						<th className={`count-label user-select`}>O</th>
						<td className={out > 0 ? `count-out` : ``}></td>
						<td className={out > 1 ? `count-out` : ``}></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default TableCount;
