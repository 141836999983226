import './App.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Game, Result, Status } from './Result';
import Store from './FireStore';
import moment from 'moment';
import Util from './Util';
import InfiniteScroll from 'react-infinite-scroller';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import GameListItem from './GameListItem';
import { useNavigate } from 'react-router-dom';

// ページングの件数
export const GAME_LIST_LIMIT = 30;
export const TITLE_HEIGHT = 85;

const GameListPage: React.FC = () => {
	// Game データ
	const [result, setResult] = useState<Result<Game[]>>(() => {
		return { status: Status.CREATE };
	});
	// 再読み込み判定
	const [hasMore, setHasMore] = useState(true);

	const findGames = useCallback(async (page, games) => {
		const index = page * GAME_LIST_LIMIT - 1;
		const doc = page > 0 && games ? games[index] : undefined;
		const res = await Store.findGames(doc);
		if (page > 0 && games && res.data) {
			setHasMore(res.status !== Status.ADD_FINISH);
			setResult({ status: res.status, data: [...games, ...res.data] });
		} else {
			setResult(res);
		}
	}, []);

	const navigate = useNavigate();
	const onClickGame = useCallback(
		(game) => {
			navigate(`/game/${game.id}`);
		},
		[navigate]
	);

	useEffect(() => {
		// 初回の Game 取得
		findGames(0, undefined);
	}, [findGames]);

	const { status, data } = result;

	// ログ
	console.log(data?.map((game) => moment.unix(game.date.seconds).format('YYYY-MM-DD')).join('\n'));

	// タイトルとリストの高さ style
	const listStyle = {
		height: `${window.document.documentElement.clientHeight - TITLE_HEIGHT}px`,
	};
	const titleStyle = { height: `${TITLE_HEIGHT}px` };

	return (
		<>
			{data ? (
				<>
					<h1 className={`game-title`} style={titleStyle}>{`GAMES`}</h1>
					<InfiniteScroll
						className={`infinite-list`}
						style={listStyle}
						pageStart={0}
						loadMore={(page) => {
							setTimeout(() => {
								findGames(page, data);
							}, 1000);
						}}
						hasMore={hasMore}
						loader={
							<div className={`loading-add-game`} key={0}>
								<p>Loading ...</p>
							</div>
						}
						useWindow={false}
					>
						<ul className={`list`}>
							{data.map((game) => {
								return <GameListItem key={game.id} game={game} onClicked={onClickGame} />;
							})}
						</ul>
					</InfiniteScroll>
				</>
			) : (
				<>{status === Status.ERROR && <p className={`error-msg`}>{`Game not found.`}</p>}</>
			)}
		</>
	);
};

export default GameListPage;
