import {
	collection,
	doc,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	QueryDocumentSnapshot,
	startAfter,
	startAt,
	where,
} from 'firebase/firestore';
import { Dispatch, SetStateAction } from 'react';
import { db } from './App';
import { GAME_LIST_LIMIT } from './GameListPage';
import { Result, Game, Status } from './Result';

const GAME_COLLECTION_NAME = 'games';

/**
 * Gameの取得
 * @param id
 * @returns
 */
const findGames = async (game?: Game): Promise<Result<Game[]>> => {
	try {
		if (game) {
			const docRef = doc(db, GAME_COLLECTION_NAME, game.id);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const q = query(
					collection(db, GAME_COLLECTION_NAME),
					orderBy('date', 'desc'),
					limit(GAME_LIST_LIMIT),
					startAfter(docSnap)
				);
				const querySnapshot = await getDocs(q);
				const games = querySnapshot.docs.map((doc) => {
					return doc.data() as Game;
				});
				if (games.length === 0 || games.length < GAME_LIST_LIMIT) {
					return { status: Status.ADD_FINISH, data: games };
				}
				return { status: Status.SUCCESS, data: games };
			} else {
				return { status: Status.ERROR };
			}
		} else {
			const q = query(collection(db, GAME_COLLECTION_NAME), orderBy('date', 'desc'), limit(GAME_LIST_LIMIT));
			const querySnapshot = await getDocs(q);
			const games = querySnapshot.docs.map((doc) => {
				return doc.data() as Game;
			});
			return { status: Status.SUCCESS, data: games };
		}
	} catch (e) {
		console.log(e);
	}
	return { status: Status.ERROR };
};

/**
 * Gameの取得
 * @param id
 * @returns
 */
const findGame = async (id: string): Promise<Result<Game>> => {
	const docRef = doc(db, GAME_COLLECTION_NAME, id);
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		const game = docSnap.data();
		console.log(game);
		return { status: Status.SUCCESS, data: game } as Result<Game>;
	}
	return { status: Status.ERROR };
};

/**
 * Gameリアルタイム
 */
const syncGame = (id: string, setResult: Dispatch<SetStateAction<Result<Game>>>) => {
	onSnapshot(doc(db, GAME_COLLECTION_NAME, id), async (doc) => {
		setResult((res) => {
			return { status: Status.LOADING, data: res.data };
		});
		await timeout(1000);
		let result;
		if (doc.exists()) {
			const game = doc.data() as Game;
			result = { status: Status.SUCCESS, data: game } as Result<Game>;
		} else {
			result = { status: Status.ERROR } as Result<Game>;
		}
		setResult(result);
	});
};

const timeout = (delay: number) => {
	return new Promise((res) => setTimeout(res, delay));
};

export default {
	findGame,
	findGames,
	syncGame,
} as const;
