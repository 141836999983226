import './App.css';
import logo from './logo.png';
import React from 'react';

const Home: React.FC = () => {
	return (
		<div className="home">
			<img src={logo} className={`App-logo`} alt="logo" />
			<h1 className="title">umpire</h1>
			<div className="App-contents">
				<a className="menu-link" href="/games">
					<span className="game-button">GAMES</span>
				</a>

				<a
					className="App-link"
					href="http://play.google.com/store/apps/details?id=com.c.local.umpire&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
				>
					<img
						className="App-link-img"
						alt="Google Play で手に入れよう"
						src="https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png"
					/>
				</a>
			</div>
		</div>
	);
};

export default Home;
