import './App.css';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Store from './FireStore';
import LocalStorage from './LocalStorage';
import { Game, Result, Status } from './Result';
import TableCount from './TableCount';
import TableScore from './TableScore';

type Props = { id: string };

const GamePage: React.FC = () => {
	// Game id
	const { id } = useParams<Props>();
	// Game データ
	const [result, setResult] = useState<Result<Game>>(() => {
		return { status: Status.CREATE };
	});

	useEffect(() => {
		// (async () => await getData())();
		// Game の取得、リスナー
		if (id) {
			Store.syncGame(id as string, setResult);
		} else {
			setResult({ status: Status.ERROR });
		}
	}, [id]);

	const { status, data } = result;

	return (
		<>
			{data ? (
				<>
					<TableScore game={data} status={status} />
					<TableCount game={data} />
				</>
			) : (
				<>{status === Status.ERROR && <p className={`error-msg`}>{`Game not found.`}</p>}</>
			)}
		</>
	);
};

export default GamePage;
