import moment from 'moment';
import React from 'react';

import { Game, Status } from './Result';

type Props = { game: Game; status: Status };

const ScoreTable: React.FC<Props> = (props: Props) => {
	const { game, status } = props;
	const {
		score: { first, second },
	} = game;
	const date = moment.unix(game.date.seconds);
	return (
		<>
			<table className={`score-table`}>
				<caption className={`game-location-date`}>
					{game.location} {` `} {date.format('YYYY-MM-DD')}
				</caption>
				<tbody>
					<tr>
						<th className={status === Status.LOADING ? `loading` : ``}>
							{status === Status.LOADING ? `Loading` : ` `}
						</th>
						<th>1</th>
						<th>2</th>
						<th>3</th>
						<th>4</th>
						<th>5</th>
						<th>6</th>
						<th>7</th>
						<th>8</th>
						<th>9</th>
						<th>R</th>
						<th>H</th>
						<th>E</th>
					</tr>
					<tr>
						<td>{game.teamA}</td>
						{first.map((p, i) => {
							return (
								<td key={i} className={p === '' ? `empty` : `not-empty`}>
									{p}
								</td>
							);
						})}
					</tr>
					<tr>
						<td>{game.teamB}</td>
						{second.map((p, i) => {
							return <td key={i}>{p}</td>;
						})}
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default ScoreTable;
