const firebase = {
	apiKey: 'AIzaSyDAN7N0VASpa8omEZoXhct3IuhsTftLaiU',
	authDomain: 'umpire-c4b62.firebaseapp.com',
	databaseURL: 'https://umpire-c4b62.firebaseio.com',
	projectId: 'umpire-c4b62',
	storageBucket: 'umpire-c4b62.appspot.com',
	messagingSenderId: '686648219536',
};

export default {
	firebase,
} as const;
