export const Status = {
	CREATE: 'CREATE',
	LOADING: 'LOADING',
	SUCCESS: 'SUCCESS',
	ADD_FINISH: 'ADD_FINISH',
	ERROR: 'ERROR',
};
export type Status = typeof Status[keyof typeof Status];

export interface Result<T> {
	status: Status;
	data?: T;
}

export interface Count {
	ball: number;
	strike: number;
	out: number;
}

export interface Score {
	first: string[];
	second: string[];
}

export interface Game {
	id: string;
	count: Count;
	date: { seconds: number; nanoseconds: number };
	location: string;
	score: Score;
	teamA: string;
	teamB: string;
	uid: string;
	update: { seconds: number; nanoseconds: number };
}
