import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import GamePage from './GamePage';
import NotFound from './NotFound';
import About from './Abut';
import { initializeApp } from 'firebase/app';

import { getFirestore } from 'firebase/firestore';
import Config from './Config';
import GameListPage from './GameListPage';

export const app = initializeApp(Config.firebase);
export const db = getFirestore(app);

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/about" element={<About />} />
				<Route path="/games" element={<GameListPage />} />
				<Route path="/game/:id" element={<GamePage />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</>
	);
}

export default App;
